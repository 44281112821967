@media screen {
    .print-page {
        position: relative;
        top: 0;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 30px;
        border: 1px solid black;
    }
}

@media print {
    .print-page {
        position: fixed;
        top: 0;
        left: 0;
        border: 0;
    }
    .no-print {
        display: none;
    }
    #main-navbar {
        display: none !important;
    }

    @page {
        margin: 0;
    }
}